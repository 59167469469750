import React             from 'react';
import useScript         from 'react-script-hook';
import { GiDiamondRing } from 'react-icons/gi';

import Wrapper                       from '@interness/web-core/src/components/structure/Wrapper/Wrapper';
import Heading                       from '@interness/web-core/src/components/text/Heading/Heading';
import Spacer                        from '@interness/web-core/src/components/structure/Spacer/Spacer';
import Spinner, { SpinnerContainer } from '@interness/web-core/src/components/modules/Spinner/Spinner';
import SEO                           from '@interness/web-core/src/components/modules/SEO/SEO';

const Konfigurator = () => {
  const [loading] = useScript({
    src: 'https://konfigurator.woerner-schmuck.de/integration.js?jewelerId=',
    checkForExisting: true
  });
  return (
    <Wrapper>
      <SEO title="Trauringkonfigurator"/>
      <Spacer/>
      <Heading icon={<GiDiamondRing/>}>Trauringkonfigurator</Heading>
      <Spacer/>
      {loading && <SpinnerContainer><Spinner/></SpinnerContainer>}
      <div id="metrix-configurator" className="not-integrated">
        <div className="page-loader">
          <div className="loading-spinner"></div>
        </div>
      </div>
    </Wrapper>
  )
};

export default Konfigurator;
